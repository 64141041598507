import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as viewPowerMeteringAction from "../../actions/viewPowerMetering";
import * as viewMoterAnalysis from "../../actions/viewMotorAnalysis";
import NumericLable from "react-pretty-numbers";
import TopNavigation from "../common/topNavigation";
import config from "../../api/config";
import * as logOffAction from "../../actions/logOffAction";
import SecTopHeader from "../common/SecTopHeader";
import Excitation from "../common/ExcitationComponent";
import DCMotor from "../common/DCMotorComponent";
import Options from "../common/more_options";
import equipmentApi from "../../api/equipement";

class MotorAnalysisPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.refreshPage = this.refreshPage.bind(this);
        this.logOff = this.logOff.bind(this);
        this.applyClassName = this.applyClassName.bind(this);
        this.state = {
            showOptions: false,
            nodeData: [
                {
                    serialNumber: "---",
                    ipAddress: "---",
                    macAddress: "---",
                },
            ],
            showNodeData: false,
        };
    }

    toggleOptions() {
        this.setState((prevState) => {
            return { ...prevState, showOptions: !prevState.showOptions };
        });
    }

    componentDidMount() {
        if (window.equPortfolioPageInterval !== undefined) {
            clearTimeout(window.equPortfolioPageInterval);
        }

        if (window.powerMeterAdminPageInterval !== undefined) {
            clearTimeout(window.powerMeterAdminPageInterval);
        }

        $(".loaderPosition").removeClass("hidden");
        if (this.props.analysis.serialNumber !== undefined) {
            this.interval = setInterval(this.refreshPage, 900000);
            this.props.actionViewPowerMetering.setIntervalOne(this.interval);
        }

        //To terminate the effect of last pending thread of power metering call
        setTimeout(
            function () {
                console.log("PROP MP", this.props);
                this.props.actionViewMotorAnalysis.loadMotorAnalysis(undefined);
            }.bind(this),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    refreshPage() {
        this.props.actionViewMotorAnalysis.loadMotorAnalysis(undefined);
    }

    applyClassName(name) {
        if (name !== undefined && name.length > 15) {
            return "eqType eqTypesplmar";
        } else {
            return "eqType ";
        }
    }

    logOff() {
        this.props.logOffAction.logOut();
    }

    _changeIconStatus = () => {
        this.setState((prevState) => {
            return { ...prevState, showNodeData: !prevState.showNodeData };
        });
        if (!this.state.showNodeData) {
            equipmentApi.getNodeNetworkConfigs(this.props.token, this.props.selectedSerialNumber).then((data) => {
                this.setState((prevState) => {
                    return { ...prevState, nodeData: data };
                });
            });
        }
    };

    render() {
        console.log("Moter", this.props);
        let optionWithoutClassAndDecimal = {
            justification: "R",
            locales: "en-AU",
            percentage: false,
            precision: 0,
            wholenumber: "ceil",
            commafy: true,
            title: true,
        };

        let option = {
            justification: "R",
            locales: "en-AU",
            percentage: false,
            precision: config.datapoint_precision,
            commafy: true,
            title: true,
            cssClass: ["pr5"],
        };

        let optionWithoutClass = {
            justification: "R",
            locales: "en-AU",
            percentage: false,
            precision: config.datapoint_precision,
            commafy: true,
            title: true,
        };

        let optionCeil = {
            justification: "R",
            locales: "en-AU",
            percentage: false,
            precision: config.datapoint_precision,
            commafy: true,
            title: true,
            wholenumber: "ceil",
            cssClass: ["pr5"],
        };

        console.log("Analysis", this.props);

        setTimeout(function () {
            $(".loaderPosition").addClass("hidden");
        }, 500);

        return (
            <div className="reportPage">
                <div className="wrapperH">
                    <div className="loginH clearfix">
                        <div className="logosmallH">
                            <a href="#">&nbsp;</a>
                        </div>
                        <div className="logoff ">
                            <div className="three_line" onClick={this.toggleOptions.bind(this)}>
                                &nbsp;
                            </div>
                        </div>
                    </div>

                    <div className="clearfix">
                        <div>
                            {this.state.showOptions ? (
                                <div className="options_menu">
                                    <Options />
                                </div>
                            ) : null}
                        </div>

                        <TopNavigation activeNode={4} />
                        <SecTopHeader
                            name={this.props.analysis.name}
                            serialNumber={this.props.analysis.serialNumber}
                            secondarySerialNumber={this.props.analysis.secondarySerialNumber}
                            facilityName={this.props.analysis.facilityName}
                        />
                        <div className="blueBoxH bdRadius spmb30">
                            <div className="titleH clearfix">
                                <div className="subTitleH statusTitleContainer">
                                    {this.props.analysis.secondarySerialNumber ? (
                                        <div className="connectedStatusContainer">
                                            <div
                                                className={
                                                    "status" +
                                                    (this.props.analysis.connectedStatus ?? "")
                                                        .replace("ON", "green")
                                                        .replace("OFFLINE", "gray") +
                                                    " connectedStatusPrimary"
                                                    // this.props.node.status.toLowerCase(.)
                                                }
                                            ></div>
                                            <div
                                                className={
                                                    "status" +
                                                    (this.props.analysis.fieldConnectedStatus ?? "")
                                                        .replace("ON", "green")
                                                        .replace("OFFLINE", "gray") +
                                                    " connectedStatusSecondary"
                                                }
                                            ></div>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                "newEqStateKW status" +
                                                (this.props.analysis.connectedStatus ?? "")
                                                    .replace("ON", "green")
                                                    .replace("OFFLINE", "gray") +
                                                " newEqState eqKwStatus connectedStatusBase"
                                                // this.props.node.status.toLowerCase(.)
                                            }
                                        ></div>
                                    )}
                                    <span className="performH allCaps">MOTOR ANALYSIS</span>
                                </div>

                                <div className="dateTimeH">
                                    <span className="waveHdRight">
                                        <span className="spldbBL">performed: </span>{" "}
                                        {this.props.analysis.displayTimeElapsed}{" "}
                                    </span>
                                </div>

                                {/* MOTOR ANALYSIS <span className="performH">performed {this.props.analysis.displayTimestamp} </span> */}
                            </div>
                            <div className="clearfix">
                                <div className="specialWrapper">
                                    <div className="meterH">
                                        <div className="padLefRigH">
                                            <div className="rectH">
                                                <ul>
                                                    <li>
                                                        <span className="rectHead minMl50">Voltage</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="defaultAlert splDefaultAlert">
                                                                <span className="splMaAlert target_element9" />
                                                            </span>
                                                            <span className="greenTxt splPl1 minWid75">
                                                                <NumericLable params={option}>
                                                                    {this.props.analysis.eqType == "ac" &&
                                                                    this.props.analysis.eqTypeSub == "none"
                                                                        ? this.props.analysis.voltageLine ?? "---"
                                                                        : this.props.analysis.voltage ?? "---"}
                                                                </NumericLable>
                                                            </span>
                                                            <span className="perSign wid26">Volts</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="rectHead minMl10">Current</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="defaultAlert splDefaultAlert">
                                                                <span className="splMaAlert target_element10">
                                                                    &nbsp;
                                                                </span>
                                                            </span>
                                                            <span className="greenTxt splPl1 minWid75">
                                                                <NumericLable params={option}>
                                                                    {this.props.analysis.current ?? "---"}
                                                                </NumericLable>
                                                            </span>
                                                            <span className="perSign wid26">Amps</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="rectHead minMl10">HP</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="defaultAlert splDefaultAlert">
                                                                <span className="splMaAlert target_element11">
                                                                    &nbsp;
                                                                </span>
                                                            </span>
                                                            <span className="greenTxt splPl1 minWid75">
                                                                <NumericLable params={option}>
                                                                    {this.props.analysis.eqType == "ac" &&
                                                                    this.props.analysis.eqTypeSub == "none"
                                                                        ? this.props.analysis.rawHp ?? "---"
                                                                        : this.props.analysis.hp ?? "---"}
                                                                </NumericLable>
                                                            </span>
                                                            <span className="perSign wid26">HP</span>
                                                        </span>
                                                    </li>
                                                    {this.props.analysis.equipmentType !== "DCMotor" ? (
                                                        <li>
                                                            <span className="rectHead minMl50">{"Frequency"}</span>
                                                            <span className="goldBox dbi splBdRadius">
                                                                <span className="defaultAlert splDefaultAlert">
                                                                    <span className="splMaAlert target_element12">
                                                                        &nbsp;
                                                                    </span>
                                                                </span>
                                                                <span className="greenTxt splPl1 minWid75">
                                                                    <NumericLable params={option}>
                                                                        {this.props.analysis.lineFrequency ?? "---"}
                                                                    </NumericLable>
                                                                </span>
                                                                <span className="perSign wid26">{"Hz"}</span>
                                                            </span>
                                                        </li>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ul>
                                            </div>

                                            <div className="circleH splHgt254">
                                                <div className="firstCir">
                                                    <div className="secCir">
                                                        <div className="thirdCir">
                                                            <div className="divposition">
                                                                <div
                                                                    className={this.applyClassName(
                                                                        this.props.analysis.displayEquipmentType
                                                                    )}
                                                                >
                                                                    {" "}
                                                                    {this.props.analysis.displayEquipmentType ?? ""}
                                                                </div>
                                                                <span className="goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading">
                                                                    <span className="dis">
                                                                        <NumericLable params={option}>
                                                                            {this.props.analysis.eqType == "ac" &&
                                                                            this.props.analysis.eqTypeSub == "none"
                                                                                ? this.props.analysis.rawPowerKw ??
                                                                                  "---"
                                                                                : this.props.analysis.power
                                                                                ? (
                                                                                      this.props.analysis.power / 1000
                                                                                  ).toFixed(config.datapoint_precision)
                                                                                : "---"}
                                                                        </NumericLable>
                                                                    </span>
                                                                    <span className="kwFont">kW</span>
                                                                </span>
                                                                {/* <div className="circlecontent">
                                                                    <span className="hourH">
                                                                        <NumericLable params={optionCeil}>
                                                                            {this.props.analysis.runningTimeDays}
                                                                        </NumericLable>
                                                                    </span>
                                                                    <span className="hrsTxt"> Days</span>
                                                                </div> */}
                                                            </div>
                                                            {/*<div className="circlecontent">*/}
                                                            {/*<span className="hourH"><NumericLable*/}
                                                            {/*    params={optionCeil}>{this.props.analysis.runningTimeDays}</NumericLable></span><span*/}
                                                            {/*className="hrsTxt"> Days</span>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.analysis.equipmentType !== undefined &&
                            this.props.analysis.equipmentType === "SynchronousMotor" && (
                                <Excitation
                                    showContent="SyncMoterAnalysis"
                                    fieldCurrent={this.props.analysis.fieldCurrent}
                                    fieldCurrentDeltaLower={this.props.analysis.fieldCurrentDeltaLower}
                                    fieldCurrentDeltaUpper={this.props.analysis.fieldCurrentDeltaUpper}
                                    fieldVoltage={this.props.analysis.fieldVoltage}
                                    fieldVoltageDeltaLower={this.props.analysis.fieldVoltageDeltaLower}
                                    fieldVoltageDeltaUpper={this.props.analysis.fieldVoltageDeltaUpper}
                                />
                            )}

                        {this.props.analysis.equipmentType !== "DCMotor" ? (
                            <div className="blueBoxH bdRadius table-responsive spmb30">
                                <div className="specialWrapper">
                                    <table className="table table-condensed borderless measureTble deltaH imbalancH">
                                        <thead>
                                            <tr>
                                                <th>Power Factor</th>
                                                <th>RPM</th>
                                                <th>Torque (lbs ft)</th>
                                                <th>V/f</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" className="mt3 dbBL" />
                                            </tr>
                                            <tr>
                                                <td className="txtCenter">
                                                    <span className="goldBox splBdRadius ml6 splWid131 text-center">
                                                        <span className="defaultAlert">
                                                            <span className="maAlert target_element5">&nbsp;</span>
                                                        </span>
                                                        <span className="greenTxt">
                                                            {" "}
                                                            <NumericLable params={optionWithoutClass}>
                                                                {this.props.analysis.eqType == "ac" &&
                                                                this.props.analysis.eqTypeSub == "none"
                                                                    ? this.props.analysis.rawPowerFactorLine ?? "---"
                                                                    : this.props.analysis.powerFactor ?? "---"}
                                                            </NumericLable>{" "}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td className="txtCenter">
                                                    <span className="goldBox splBdRadius ml6 splWid131 text-center">
                                                        <span className="defaultAlert">
                                                            <span className="maAlert target_element6">&nbsp;</span>
                                                        </span>
                                                        <span className="greenTxt">
                                                            {" "}
                                                            <NumericLable
                                                                params={
                                                                    this.props.analysis.rpm
                                                                        ? optionWithoutClassAndDecimal
                                                                        : option
                                                                }
                                                            >
                                                                {this.props.analysis.rpm ?? "---"}
                                                            </NumericLable>{" "}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td className="txtCenter">
                                                    <span className="goldBox splBdRadius ml6 splWid131 text-center">
                                                        <span className="defaultAlert">
                                                            <span className="maAlert target_element7">&nbsp;</span>
                                                        </span>
                                                        <span className="greenTxt">
                                                            {" "}
                                                            <NumericLable params={optionWithoutClass}>
                                                                {this.props.analysis.torque ?? "---"}
                                                            </NumericLable>{" "}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td className="txtCenter">
                                                    <span className="goldBox splBdRadius splWid131 text-center">
                                                        <span className="defaultAlert">
                                                            <span className="maAlert target_element8">&nbsp;</span>
                                                        </span>
                                                        <span className="greenTxt">
                                                            {" "}
                                                            <NumericLable params={optionWithoutClass}>
                                                                {this.props.analysis.eqType == "ac" &&
                                                                this.props.analysis.eqTypeSub == "none"
                                                                    ? this.props.analysis.vDivFLine ?? "---"
                                                                    : this.props.analysis.vDivF ?? "---"}
                                                            </NumericLable>{" "}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {this.props.analysis.equipmentType !== "DCMotor" ? (
                            <div className="blueBoxH bdRadius table-responsive spmb30">
                                <div className="specialWrapper">
                                    <table className="table table-condensed borderless measureTble deltaH imbalancH">
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Imbalance</th>
                                                <th>
                                                    <span className="centerTxt">THD</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="3" className="mt3 dbBL" />
                                            </tr>
                                            <tr>
                                                <td className="valueTxt txt-left">
                                                    <span className="adjusTxt">Voltage</span>
                                                </td>
                                                <td className="txtCenter">
                                                    <span className="goldBox splBdRadius ml6 splWid130">
                                                        {this.props.analysis.voltageImbalance !== null ? (
                                                            <span className="defaultAlert">
                                                                <span className="maAlert target_element1">&nbsp;</span>
                                                            </span>
                                                        ) : null}
                                                        <span className="greenTxt wid71">
                                                            {" "}
                                                            <NumericLable params={option}>
                                                                {this.props.analysis.eqType == "ac" &&
                                                                this.props.analysis.eqTypeSub == "none"
                                                                    ? this.props.analysis.voltageImbalanceLine ?? "---"
                                                                    : this.props.analysis.voltageImbalance ?? "---"}
                                                            </NumericLable>{" "}
                                                        </span>
                                                        {this.props.analysis.voltageImbalance !== null ? (
                                                            <span className="perSign"> %</span>
                                                        ) : null}
                                                    </span>
                                                </td>
                                                <td className="txtCenter">
                                                    <span className="goldBox splBdRadius splWid130">
                                                        <span>
                                                            {this.props.analysis.voltageThd !== null ? (
                                                                <span className="defaultAlert">
                                                                    <span className="maAlert target_element2">
                                                                        &nbsp;
                                                                    </span>
                                                                </span>
                                                            ) : null}
                                                            <span className="greenTxt wid71">
                                                                {" "}
                                                                <NumericLable params={option}>
                                                                    {this.props.analysis.eqType == "ac" &&
                                                                    this.props.analysis.eqTypeSub == "none"
                                                                        ? this.props.analysis.voltageThdLine ?? "---"
                                                                        : this.props.analysis.voltageThd ?? "---"}
                                                                </NumericLable>{" "}
                                                            </span>
                                                            {this.props.analysis.voltageThd !== null ? (
                                                                <span className="perSign"> %</span>
                                                            ) : null}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="valueTxt txt-left">
                                                    <span className="adjusTxt">Current</span>
                                                </td>
                                                <td className="txtCenter">
                                                    <span className="goldBox splBdRadius ml6 splWid130">
                                                        {this.props.analysis.currentImbalance !== null ? (
                                                            <span className="defaultAlert">
                                                                <span className="maAlert target_element3">&nbsp;</span>
                                                            </span>
                                                        ) : null}
                                                        <span className="greenTxt wid71">
                                                            {" "}
                                                            <NumericLable params={option}>
                                                                {this.props.analysis.currentImbalance ?? "---"}
                                                            </NumericLable>{" "}
                                                        </span>
                                                        {this.props.analysis.currentImbalance !== null ? (
                                                            <span className="perSign"> %</span>
                                                        ) : null}
                                                    </span>
                                                </td>
                                                <td className="spMb5 dbBL txtCenter">
                                                    <span className="goldBox splBdRadius splWid130">
                                                        {this.props.analysis.currentThd !== null ? (
                                                            <span className="defaultAlert">
                                                                <span className="maAlert target_element4">&nbsp;</span>
                                                            </span>
                                                        ) : null}
                                                        <span className="greenTxt wid71">
                                                            {" "}
                                                            <NumericLable params={option}>
                                                                {this.props.analysis.currentThd ?? "---"}
                                                            </NumericLable>{" "}
                                                        </span>
                                                        {this.props.analysis.currentThd !== null ? (
                                                            <span className="perSign"> %</span>
                                                        ) : null}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {this.props.analysis.equipmentType !== undefined &&
                            this.props.analysis.equipmentType === "DCMotor" && (
                                <DCMotor
                                    dcCurrent={this.props.analysis.dcCurrent}
                                    dcCurrentDeltaLower={this.props.analysis.dcCurrentDeltaLower}
                                    dcCurrentDeltaUpper={this.props.analysis.dcCurrentDeltaUpper}
                                    dcVoltage={this.props.analysis.dcVoltage}
                                    dcVoltageDeltaLower={this.props.analysis.dcVoltageDeltaLower}
                                    dcVoltageDeltaUpper={this.props.analysis.dcVoltageDeltaUpper}
                                />
                            )}
                        {this.props.analysis.equipmentType !== undefined &&
                            this.props.analysis.equipmentType === "DCMotor" && (
                                <Excitation
                                    showContent="SyncMoterAnalysis"
                                    fieldCurrent={this.props.analysis.fieldCurrent}
                                    fieldCurrentDeltaLower={this.props.analysis.fieldCurrentDeltaLower}
                                    fieldCurrentDeltaUpper={this.props.analysis.fieldCurrentDeltaUpper}
                                    fieldVoltage={this.props.analysis.fieldVoltage}
                                    fieldVoltageDeltaLower={this.props.analysis.fieldVoltageDeltaLower}
                                    fieldVoltageDeltaUpper={this.props.analysis.fieldVoltageDeltaUpper}
                                />
                            )}

                        {/* <div className='blueBoxH bdRadius table-responsive spmb30'>
							<div className='specialWrapper'>
								<table className='table table-condensed borderless measureTble deltaH'>
									<thead>
										<tr>
											<th>&nbsp;</th>
											<th>&nbsp;</th>
											<th>
												<span className=''>ST:Delta</span>
											</th>
											<th>
												<span className='deltaTxt'>LT:Delta</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td colSpan='4' className='mt3 dbBL' />
										</tr>
										<tr>
											<td className='valueTxt txt-left'>
												<span className='adjusTxt'>Voltage</span>
											</td>
											<td className='text-right'>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.voltage !== null &&
													this.props.analysis.voltage !== undefined ? (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>
																{this.props.analysis.voltage}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign wid48'>Volts</span>
												</span>
											</td>
											<td>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.voltageStDelta !== null &&
													this.props.analysis.voltageStDelta !== undefined ? (
														<span className='greenTxt text-right'>
															<NumericLable params={option}>
																{this.props.analysis.voltageStDelta}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign'>%</span>
												</span>
											</td>
											<td>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.voltageLtDelta !== null &&
													this.props.analysis.voltageLtDelta !== undefined ? (
														<span className='greenTxt text-right'>
															<NumericLable params={option}>
																{this.props.analysis.voltageLtDelta}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign'>%</span>
												</span>
											</td>
										</tr>
										<tr>
											<td className='valueTxt txt-left'>
												<span className='adjusTxt'>Current</span>
											</td>
											<td className='text-right'>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.current !== null ? (
														<span className='greenTxt text-right'>
															<NumericLable params={option}>
																{this.props.analysis.current}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign wid48'>Amps</span>
												</span>
											</td>
											<td>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.currentStDelta !== null ? (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>
																{this.props.analysis.currentStDelta}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign'>%</span>
												</span>
											</td>
											<td>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.currentLtDelta !== null ? (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>
																{this.props.analysis.currentLtDelta}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign'>%</span>
												</span>
											</td>
										</tr>
										<tr>
											<td className='valueTxt spMb5 dbBL txt-left'>
												<span className='adjusTxt'>HP</span>
											</td>
											<td className='text-right'>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.hp !== null ? (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>
																{this.props.analysis.hp}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign wid48'>HP</span>
												</span>
											</td>
											<td>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.hpStDelta !== null ? (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>
																{this.props.analysis.hpStDelta}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign'>%</span>
												</span>
											</td>
											<td>
												<span className='goldBox splBdRadius splDesk'>
													{this.props.analysis.hpLtDelta !== null ? (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>
																{this.props.analysis.hpLtDelta}
															</NumericLable>
														</span>
													) : (
														<span className='greenTxt  text-right'>
															<NumericLable params={option}>{'---'}</NumericLable>
														</span>
													)}

													<span className='perSign'>%</span>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className='clearfix'>
							<div className='blueBoxH bdRadius table-responsive spmb30'>
								<div className='specialWrapper'>
									<table className='table table-condensed borderless measureTble deltaH'>
										<thead>
											<tr>
												<th className='txtLeft'>
													<span className='ml20'>LC Filter</span>
												</th>
												<th>
													<span>Phase A</span>
												</th>
												<th>
													<span>Phase B</span>
												</th>
												<th>
													<span>Phase C</span>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td colSpan='4' className='mt3 dbBL' />
											</tr>
											<tr>
												<td className='valueTxt txt-left'>
													<span className='adjusTxt'>Ripple Calculation</span>
												</td>
												<td className='text-right'>
													<span className='goldBox splBdRadius splCenterDesk'>
														<span className='defaultAlert'>
															<span className='maAlert target_element13'>&nbsp;</span>
														</span>

														{this.props.analysis.vaLCValue !== null ? (
															<span className='greenTxt splpdLR'>
																<NumericLable params={option}>
																	{this.props.analysis.vaLCValue}
																</NumericLable>
															</span>
														) : (
															<span className='greenTxt splpdLR'>
																<NumericLable params={option}>{'---'}</NumericLable>
															</span>
														)}
													</span>
												</td>
												<td>
													<span className='goldBox splBdRadius splCenterDesk'>
														<span className='defaultAlert'>
															<span className='maAlert target_element14'>&nbsp;</span>
														</span>

														{this.props.analysis.vbLCValue !== null ? (
															<span className='greenTxt splpdLR'>
																<NumericLable params={option}>
																	{this.props.analysis.vbLCValue}
																</NumericLable>
															</span>
														) : (
															<span className='greenTxt splpdLR'>
																<NumericLable params={option}>{'---'}</NumericLable>
															</span>
														)}
													</span>
												</td>
												<td>
													<span className='goldBox splBdRadius splCenterDesk'>
														<span className='defaultAlert'>
															<span className='maAlert target_element15'>&nbsp;</span>
														</span>

														{this.props.analysis.vcLCValue !== null ? (
															<span className='greenTxt splpdLR'>
																<NumericLable params={option}>
																	{this.props.analysis.vcLCValue}
																</NumericLable>
															</span>
														) : (
															<span className='greenTxt splpdLR'>
																<NumericLable params={option}>{'---'}</NumericLable>
															</span>
														)}
													</span>
												</td>
											</tr>
											<tr>
												<td colSpan='4' className='mt3 dbBL' />
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div> */}

                        <div className="clearfix">
                            <div className="blueBoxH bdRadius table-responsive">
                                <div className="specialWrapper">
                                    <table className="table table-condensed ">
                                        <thead>
                                            <tr>
                                                <th className="txtLeft">
                                                    <span className="ml20 ratingMainHeader">Name Plate Data</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="ratingHeader">Motor Ratings</td>
                                            </tr>
                                            <tr>
                                                <td className="noTopBorder">
                                                    <table className="ratingFullWid">
                                                        <tr>
                                                            <td className="ratingH">
                                                                <table>
                                                                    <tr>
                                                                        <td>Line Frequency</td>
                                                                        <td>=</td>
                                                                        <td className="txt-right">
                                                                            {this.props.analysis.npLineFrequency !==
                                                                            null
                                                                                ? this.props.analysis.npLineFrequency
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Voltage</td>
                                                                        <td>=</td>
                                                                        <td className="txt-right">
                                                                            {this.props.analysis.npVoltage !== null
                                                                                ? this.props.analysis.npVoltage
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Current</td>
                                                                        <td>=</td>
                                                                        <td className="txt-right">
                                                                            {this.props.analysis.npCurrent !== null
                                                                                ? this.props.analysis.npCurrent
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Service Factor</td>
                                                                        <td>=</td>
                                                                        <td className="txt-right">
                                                                            {this.props.analysis.serviceFactor !== null
                                                                                ? this.props.analysis.serviceFactor
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>HP</td>
                                                                        <td>=</td>
                                                                        <td className="txt-right">
                                                                            {this.props.analysis.npHp !== null
                                                                                ? this.props.analysis.npHp
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>RPM</td>
                                                                        <td>=</td>
                                                                        <td className="txt-right">
                                                                            {this.props.analysis.npRpm !== null
                                                                                ? this.props.analysis.npRpm
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td className="ratingH">
                                                                <table>
                                                                    <tr>
                                                                        <td>Rotor Bars</td>
                                                                        <td>=&nbsp;&nbsp;</td>
                                                                        <td>
                                                                            {this.props.analysis.npRotorBars !== null
                                                                                ? this.props.analysis.npRotorBars
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Stator Slots</td>
                                                                        <td>=&nbsp;&nbsp;</td>
                                                                        <td>
                                                                            {this.props.analysis.npStatorSlots !== null
                                                                                ? this.props.analysis.npStatorSlots
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Poles</td>
                                                                        <td>=&nbsp;&nbsp;</td>
                                                                        <td className="txt-right">
                                                                            {this.props.analysis.npPoles !== null
                                                                                ? this.props.analysis.npPoles
                                                                                : "---"}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="blueBoxH bdRadius spmb30">
                            <div className="clearfix wid100per informationHeader">
                                <div
                                    className={this.state.showNodeData ? "equTriangleDownIcon" : "equTriangleIcon"}
                                    onClick={() => this._changeIconStatus()}
                                >
                                    <div className="eqLeftMar">Node Data</div>
                                </div>
                            </div>
                        </div>
                        {this.state.showNodeData ? (
                            <div className="clearfix">
                                <div className="blueBoxH bdRadius table-responsive">
                                    <div className="specialWrapper">
                                        <table className="table table-condensed ">
                                            <tbody>
                                                <tr>
                                                    <td className="ratingHeader">
                                                        S/N:{" "}
                                                        {String(this.state.nodeData[0].serialNumber).startsWith("9")
                                                            ? "-----"
                                                            : this.state.nodeData[0].serialNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="noTopBorder">
                                                        <table className="ratingFullWid">
                                                            <tr>
                                                                <td className="ratingH">
                                                                    <table>
                                                                        <tr>
                                                                            <td>IP Address</td>
                                                                            <td>=</td>
                                                                            <td className="txt-right">
                                                                                {this.state.nodeData[0].ipAddress !== ""
                                                                                    ? `${this.state.nodeData[0].ipAddress}`
                                                                                    : "---"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>MAC Address</td>
                                                                            <td>=</td>
                                                                            <td className="txt-right">
                                                                                {this.state.nodeData[0].macAddress !==
                                                                                ""
                                                                                    ? `${this.state.nodeData[0].macAddress}`
                                                                                    : "---"}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.nodeData[1] ? (
                                            <table className="table table-condensed ">
                                                <tbody>
                                                    <tr>
                                                        <td className="ratingHeader">
                                                            S/N:{" "}
                                                            {String(this.state.nodeData[1].serialNumber).startsWith("9")
                                                                ? "-----"
                                                                : this.state.nodeData[1].serialNumber}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="noTopBorder">
                                                            <table className="ratingFullWid">
                                                                <tr>
                                                                    <td className="ratingH">
                                                                        <table>
                                                                            <tr>
                                                                                <td>IP Address</td>
                                                                                <td>=</td>
                                                                                <td className="txt-right">
                                                                                    {this.state.nodeData[1]
                                                                                        .ipAddress !== ""
                                                                                        ? `${this.state.nodeData[1].ipAddress}`
                                                                                        : "---"}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>MAC Address</td>
                                                                                <td>=</td>
                                                                                <td className="txt-right">
                                                                                    {this.state.nodeData[1]
                                                                                        .macAddress !== ""
                                                                                        ? `${this.state.nodeData[1].macAddress}`
                                                                                        : "---"}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="blueBoxH bdRadius spmb30">
                            <div className="performedFooter">Performed: {this.props.analysis.displayTimestamp}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return Object.assign(
        {},
        {
            analysis:
                state.application.analysis.serialNumber !== undefined
                    ? state.application.analysis
                    : {
                          name: state.application.analysis.name,
                          serialNumber: "",
                          displayEquipmentType: "",
                          timezone: "",
                          voltage: "",
                          current: "",
                          hp: "",
                          rpm: "",
                          lineFrequency: "",
                          runningTime: "",
                          voltageImbalance: "",
                          currentImbalance: "",
                          voltageThd: "",
                          currentThd: "",
                          voltageStDelta: "",
                          currentStDelta: "",
                          hpStDelta: "",
                          voltageLtDelta: "",
                          currentLtDelta: "",
                          hpLtDelta: "",
                          timestamp: "",
                          vaLCValue: "",
                          vbLCValue: "",
                          vcLCValue: "",
                          voltageImbalanceAlert: "",
                          voltageThdAlert: "",
                          currentImbalanceAlert: "",
                          currentThdAlert: "",
                          powerFactor: "",
                          powerKw: "",
                          torque: "",
                          vDivF: "",
                          voltageAlert: "",
                          currentAlert: "",
                          hpAlert: "",
                          lineFrequencyAlert: "",
                          powerFactorAlert: "",
                          rpmFactorAlert: "",
                          torqueAlert: "",
                          VDivFAlert: "",
                          VaLCAlert: "",
                          VbLCAlert: "",
                          VcLCAlert: "",
                      },
        },
        state.application
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionViewPowerMetering: bindActionCreators(viewPowerMeteringAction, dispatch),
        actionViewMotorAnalysis: bindActionCreators(viewMoterAnalysis, dispatch),
        logOffAction: bindActionCreators(logOffAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MotorAnalysisPage);
