import waveApi from "../api/wave";
import $ from "jquery";
import { push } from "connected-react-router";

export function addInterval(data) {
    return function (dispatch) {
        return dispatch({ type: "INTERVAL", data });
    };
}

export function clearInterval() {
    return function (dispatch) {
        return dispatch({ type: "CLEAR_INTERVAL", data: null });
    };
}

export function loadWaveFormNew() {
    return function (dispatch, getState) {
        setTimeout(function () {
            let token = getState().application.token;
            let serialNumber = getState().application.selectedSerialNumber;
            console.log("-----------------------", getState().application);

            console.log(getState());
            function getData() {
                $(".loaderPosition").removeClass("hidden");

                waveApi
                    .getWaveFormData(token, serialNumber)
                    .then((data) => {
                        dispatch({ type: "LOAD_WAVE_FROM_NEW", data });
                        dispatch(push("/waveForm-analysis"));
                    })
                    .catch((err) => {
                        var error = { message: "NODE OFFLINE", timestamp: Math.floor(Date.now() / 1000) };
                        return dispatch({ type: "ON_ERROR_OCCURRED", error });
                    });
            }

            return getData();
        }, 0);
    };
}

export function updateWaveFormChart(data) {
    return { type: "UPDATE_WAVEFORM_CHART", data };
}

export function RedirectToEquipmentPortfolio() {
    return function (dispatch) {
        dispatch({ type: "LOAD_EQUIPMENT_PORTFOLIO" });
    };
}
